import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import styles from './escape-room.module.css'

const EscapeRoom = () => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>Escape Room - BRT</title>
        <meta property="og:title" content="Escape Room - BRT" />
      </Helmet>
      <Header rootClassName="rootClassName2"></Header>
      <div className={styles['container1']}>
        <img
          alt="image"
          src="/playground_assets/ergame-1500w.png"
          className={styles['image']}
        />
        <h1 className={styles['text']}>
          <span>Escape Room</span>
        </h1>
        <span className={styles['text2']}>
          This game simulates an escape room in sci-fi environment. The main
          goal is to escape from a spaceship before it will be too late. The
          player can invite friends for a common adventure where they have to
          try to get out as soon as possible. To do so, the player can inspect,
          combine, and interpret objects to obtain new clues to solve new
          puzzles. By studying the environment closely, the player will also
          experience a haunting story.
        </span>
      </div>
      <Footer rootClassName="rootClassName2"></Footer>
    </div>
  )
}

export default EscapeRoom
