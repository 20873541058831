import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import projectStyles from '../style.module.css'
import styles from './contactus.module.css'
import Recaptcha from '../components/recaptcha'

class Contactus extends Recaptcha {
    render() {
        return (
            <div className={styles['container']}>
                <Helmet>
                    <title>Contact us - BRT</title>
                    <meta property="og:title" content="Contact us - BRT" />
                </Helmet>
                <Header rootClassName="rootClassName1"></Header>
                <div className={styles['container01']}>
                    <div className={styles['container02']}>
                        <h1 className={styles['Heading']}>
                            <span>Contact us</span>
                        </h1>
                    </div>
                </div>
                <div className={styles['container03']}>
                    <form
                        action="https://data.brtinteractive.com/brt_site/contact_us"
                        method="POST"
                        className={styles['form']}
                    >
                        <div className={styles['container04']}>
                            <div className={styles['container05']}>
                                <div className={styles['container06']}>
                                    <div className={styles['container07']}>
                                        <label className={styles['text01']}>Your email address</label>
                                        <input
                                            type="email"
                                            name="email"
                                            enctype="Email address"
                                            placeholder="Email address"
                                            className={` ${styles['textinput']} ${projectStyles['captcha-indicator']} ${projectStyles['input']} `}
                                        />
                                    </div>
                                    <div className={styles['container08']}>
                                        <label>Email subject</label>
                                        <input
                                            type="text"
                                            name="subject"
                                            placeholder="Subject"
                                            className={` ${styles['textinput1']} ${projectStyles['captcha-indicator']} ${projectStyles['input']} `}
                                        />
                                    </div>
                                </div>
                                <div className={styles['container09']}>
                                    <label>Your message</label>
                                    <textarea
                                        cols="50"
                                        name="message"
                                        rows="10"
                                        type="message"
                                        target="message"
                                        placeholder="Message"
                                        className={` ${styles['textarea']} ${projectStyles['captcha-indicator']} ${projectStyles['textarea']} `}
                                    ></textarea>
                                </div>
                            </div>
                            <div className={styles['container10']}>
                                <div className={styles['container11']}>
                                    <input
                                        type="checkbox"
                                        name="grant"
                                        required="true"
                                        className={styles['checkbox']}
                                    />
                                    <label className={styles['text04']}>
                                        <span>
                                            By checking this box and submitting your information,
                    <span
                                                dangerouslySetInnerHTML={{
                                                    __html: ' ',
                                                }}
                                            />
                                        </span>
                                        <br></br>
                                        <span>
                                            you are granting us permission to email you.
                    <span
                                                dangerouslySetInnerHTML={{
                                                    __html: ' ',
                                                }}
                                            />
                                        </span>
                                        <br></br>
                                        <span>You may unsubscribe at any time.</span>
                                    </label>
                                </div>
                                <div className={styles['container12']}>
                                    <button
                                        name="submit"
                                        type="submit"
                                        className={` ${styles['button']} ${projectStyles['button']} `}
                                    >
                                        Send
                </button>
                                    <div
                                        data-sitekey="6LerefgdAAAAAPpWLesDesOoCY2boO5xFTr8w58m"
                                        className="g-recaptcha"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <Footer rootClassName="rootClassName1"></Footer>
            </div>
        )
    }
}

export default Contactus
