import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import styles from './privacy-policy.module.css'

const PrivacyPolicy = () => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>Privacy Policy - BRT</title>
        <meta property="og:title" content="Privacy Policy - BRT" />
      </Helmet>
      <Header rootClassName="rootClassName5"></Header>
      <div className={styles['container1']}>
        <div className={styles['container2']}>
          <h1 className={styles['Heading']}>
            <span>Privacy Policy</span>
          </h1>
          <span className={styles['text01']}>
            <span>About this Privacy Policy</span>
            <br></br>
            <span></span>
            <br></br>
            <span>
              Your privacy is very important to us, and we take it seriously.
              This policy describes our practices for collecting, using,
              disclosing of users’ personal information. This Privacy Policy
              applies to BRT Interactive games, websites, other products and
              related services (together called “Services”).
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span></span>
            <br></br>
            <span>
              All BRT Interactive Services are governed by this Privacy Policy
              and by using or accessing Services you give consent to the
              processing, use and disclosure of your data. Please do not install
              or use the Services if you do not agree to this Privacy Policy.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>What is Personal Data?</span>
            <br></br>
            <span>
              “Personal Data” is all information that identifies a person:
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>
              either directly, for example, your last name, first name, email
              address or telephone number;
            </span>
            <br></br>
            <span>
              or indirectly, for example your account ID, IP address, cookie
              identifier or game data.
            </span>
            <br></br>
            <span>What information do we collect?</span>
            <br></br>
            <span>
              In general, we try our best not to collect any Personal Data.
              However, the information we collect depends on what Services you
              use and how you use them. Please see below for more details.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>Information that you give us directly:</span>
            <br></br>
            <span>
              We collect information that you provide us, including but not
              limited to:
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>
              Your email address when you subscribe for our newsletter. You can
              unsubscribe at any time by clicking on the unsubscribe link at the
              end of each newsletter;
            </span>
            <br></br>
            <span>
              Information you provide when seeking help from us, such as your
              name, email address, telephone number and records (screenshots,
              videos) of the issues you experience;
            </span>
            <br></br>
            <span>Information that is collected automatically:</span>
            <br></br>
            <span>
              We may automatically collect information about you, including:
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>
              Log Information: We may log information about your use of our
              Services, including the type of browser you use, access times,
              pages viewed, your IP address, and the page you visited before
              navigating to our Services.
            </span>
            <br></br>
            <span>
              Device Information: We may collect information about the device
              you use to access our Services, including information about the
              device’s software and hardware, MAC address and other unique
              device identifiers, device token, mobile network information and
              time zone.
            </span>
            <br></br>
            <span>
              Usage Information: We may collect information relating to your use
              of our Services, including which applications you use and your
              game progress, scores, achievements and interactions with other
              players.
            </span>
            <br></br>
            <span>
              Consumption information: We may collect information about your
              consumption habits relating to your use of our Services, including
              which purchases you make with both virtual and real currencies.
            </span>
            <br></br>
            <span>
              Information that we obtain from other third party sources and
              partners:
            </span>
            <br></br>
            <span>
              We may receive personal data about you from a third party platform
              or distributor for example from your Steam account or other third
              parties accounts that you use to sign into our Services. The exact
              information we receive from you will depend on third party privacy
              settings, but it will typically include your basic public profile
              information, such as your username, profile picture, online ID.
              These third party sources and partners have separate and
              independent privacy policies. We have no responsibility or
              liability for the content and activities resulting from use of
              those third party services, and we encourage you to read their
              privacy policies and proceed only if you are comfortable with such
              terms.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>How do we collect the information?</span>
            <br></br>
            <span>Cookies and similar technologies:</span>
            <br></br>
            <span>
              Cookies are small data files stored on your computer’s hard drive
              or in mobile device that help us to improve our Services and your
              experience and see which areas and features of our Services are
              popular. Some examples of cookies: pixel tags, local shared
              objects, hardware-based device identifiers, flash cookies,
              operating system-based identifiers, clear GIFs and web beacons.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>Analytics technologies:</span>
            <br></br>
            <span>
              Analytics technologies use cookies to help the website operators
              analyze how visitors use the site. The information generated by
              the cookie about the visitors&apos; use of the website is
              generally transmitted to and stored by analytics technologies
              operators on their servers.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>Ad Serving technologies:</span>
            <br></br>
            <span>
              Ad Serving technologies collect and use information so that
              appropriate advertising could be served. We use an advertising
              identifier like a cookie or other device identifier. Some ad
              companies also use this information to serve you relevant ads from
              other advertisers. Examples of information collected: type of
              advertisements served and the location of the ad, length of time
              an advertisement was visible, size of the advertisement,
              advertisement response.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>Where and how we process the information?</span>
            <br></br>
            <span>
              The information is processed at BRT Interactive operating offices
              and in any other places where the parties involved with the
              processing are located. The processing is carried out using
              computers and/or IT enabled tools, following organizational
              procedures and modes strictly related to the purposes indicated.
              In addition to the BRT Interactive, in some cases, the information
              may be accessible to certain types of persons in charge, involved
              with the operation of the site (administration, sales, marketing,
              legal, system administration) or external parties (such as
              third-party technical service providers, mail carriers, hosting
              providers, IT companies, communications agencies) appointed, if
              necessary.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>How do we use information?</span>
            <br></br>
            <span>
              We may use information about you for various purposes, including
              to:
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>Provide, maintain and improve our current Services;</span>
            <br></br>
            <span>Develop new Services;</span>
            <br></br>
            <span>Facilitate communications between players;</span>
            <br></br>
            <span>
              Respond to your comments, questions and requests and provide
              customer service and support;
            </span>
            <br></br>
            <span>
              Send you technical notices, updates, security alerts and support
              and administrative messages;
            </span>
            <br></br>
            <span>
              Personalize and improve our Services and provide tailored
              advertisements, content or features;
            </span>
            <br></br>
            <span>
              Monitor and analyze trends, usage and activities in connection
              with our Services;
            </span>
            <br></br>
            <span>
              Link or combine with information we get from others to help
              understand your needs and provide you with better service;
            </span>
            <br></br>
            <span>
              Carry out any other purpose for which the information was
              collected.
            </span>
            <br></br>
            <span>Information we share with third parties</span>
            <br></br>
            <span>
              We may share some of the information we collect from you upon use
              of our Services with third parties service providers who are
              engaged by or working with us in connection with the operation of
              the Services we provide to you and/or for analytics purposes that
              may require the reproduction and display of such information.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span></span>
            <br></br>
            <span>We may disclose your Personal Data:</span>
            <br></br>
            <span></span>
            <br></br>
            <span>To our subsidiaries and affiliates;</span>
            <br></br>
            <span>
              To a buyer or other successor in the event of a merger,
              divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of BRT Interactive assets, whether
              as a going concern or as part of bankruptcy, liquidation, or
              similar proceeding, in which personal data held by BRT Interactive
              pertaining to the users of our Services is among the assets
              transferred;
            </span>
            <br></br>
            <span>
              To comply with any court order, law, or legal process, such as
              responding to a government or regulatory request;
            </span>
            <br></br>
            <span>
              Third party service providers and partners who provide data
              processing services to us or who otherwise process Personal Data
              for purposes that are described in this Privacy Policy;
            </span>
            <br></br>
            <span>
              If disclosure is necessary or appropriate to protect the rights,
              property, or safety of us, our customers, or others.
            </span>
            <br></br>
            <span>International transfers</span>
            <br></br>
            <span>
              Your Personal Data may be transferred to, and processed in,
              countries other than the country in which you are resident. These
              countries may have data protection laws that are different to the
              laws of your country. We ensure that the information is
              transferred in accordance with this Privacy Policy and as
              permitted by the applicable laws on data protection. By accepting
              our Privacy Policy or otherwise providing information to us, you
              consent to the processing and transfer of information in and to
              the U.S. and other countries.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>Security</span>
            <br></br>
            <span>
              We follow generally accepted industry standards and maintain
              appropriate safeguards to help protect the security, integrity,
              and privacy of the information we collect about you. These
              security measures are designed to protect against the accidental
              or unlawful destruction, loss, misuse, alteration, and
              unauthorized disclosure of, or access to, the information under
              our control. Specific measures include, e.g. encrypting your
              personal information in transit; giving access only to employees
              who need to have it and etc.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>Personal Data retention</span>
            <br></br>
            <span>
              We keep your personal information to enable your continued use of
              our Services, for as long as it is required in order to fulfil the
              relevant purposes described in this Privacy Policy, as may be
              required by law such as for tax and accounting purposes, or as
              otherwise communicated to you.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>Your rights</span>
            <br></br>
            <span>Right of access, rectification and erasure:</span>
            <br></br>
            <span>
              If you want to access, rectify or request erasure of your Personal
              Data that we collected, you can contact us at
              support@brtinteractive.com with your request at any time.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>Right to object:</span>
            <br></br>
            <span>
              When our processing of your Personal Data is based on legitimate
              interests according to Article 6(1)(f) of the GDPR or this Privacy
              Policy, you have the right to object to this processing. If you
              object we will no longer process your Personal Data unless there
              are compelling and prevailing legitimate grounds for the
              processing as described in Article 21 of the GDPR; in particular
              if the data is necessary for the establishment, exercise or
              defense of legal claims. You also have the right to lodge a
              complaint at a supervisory authority. Please contact us at
              support@brtinteractive.com for more information.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>
              Right to restriction of processing of your Personal Data:
            </span>
            <br></br>
            <span>
              You have the right to obtain restriction of processing of your
              Personal Data. Please contact us at support@brtinteractive.com for
              more information.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>Right to Personal Data portability:</span>
            <br></br>
            <span>
              You have the right to receive your Personal Data in a structured,
              commonly used and machine-readable format and have the right to
              transmit those data to another controller. Please contact us at
              support@brtinteractive.com for more information.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>Children</span>
            <br></br>
            <span>
              We do not knowingly collect personal information from children
              under 13 years of age. In addition, if you have not reached the
              age of majority in your state/province/territory/country of
              residence, you must obtain the consent of a parent or legal
              guardian for the collection, use and disclosure of your personal
              information in accordance with this Privacy Policy. If you do not
              have this consent, or if you are under 13 years old, do not
              provide any personal information to us.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>Changes to Privacy Policy</span>
            <br></br>
            <span>
              We reserve the right to change Privacy Policy at any time. Such
              changes, modifications, additions or deletions shall be effective
              immediately upon posting the revised Privacy Policy on this page.
              You acknowledge and agree that it is your responsibility to review
              this Privacy Policy periodically and to be aware of any
              modifications. Your continued use of our Services after such
              modifications will constitute your: (1) acknowledgment of the
              modified Privacy Policy; and (2) agreement to abide and be bound
              by the modified Privacy Policy.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>Contact us</span>
            <br></br>
            <span>
              If you have any questions or concerns about our use of your
              personal information or this Privacy Policy, please contact us at:
              support@brtinteractive.com
            </span>
          </span>
        </div>
      </div>
      <Footer rootClassName="rootClassName6"></Footer>
    </div>
  )
}

export default PrivacyPolicy
