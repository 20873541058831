import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import projectStyles from '../style.module.css'
import styles from './footer.module.css'

const Footer = (props) => {
  return (
    <footer className={` ${styles['Footer']} ${styles[props.rootClassName]} `}>
      <div className={styles['container']}>
        <div className={styles['container1']}>
          <span className={styles['text']}>{props.text2}</span>
          <div className={styles['container2']}>
            <span className={styles['text1']}>{props.text1}</span>
            <Link
              to="/privacy-policy"
              className={` ${styles['navlink']} ${projectStyles['underline']} `}
            >
              {props.text}
            </Link>
            <span className={styles['text2']}>{props.text3}</span>
          </div>
        </div>
        <img
          alt={props.image_alt}
          src={props.image_src}
          className={styles['image']}
        />
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  image_alt: 'image',
  text2: 'BRT Interactive',
  text1: '© 2022 BRT Interactive',
  text3: '',
  link_text: 'https://example.com',
  rootClassName: '',
  text: 'Privacy Policy',
  image_src:
    'https://images.unsplash.com/photo-1550745165-9bc0b252726f?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fGdhbWV8ZW58MHx8fHwxNjQxNDIwODcw&ixlib=rb-1.2.1&h=1500',
}

Footer.propTypes = {
  image_alt: PropTypes.string,
  text2: PropTypes.string,
  text1: PropTypes.string,
  text3: PropTypes.string,
  link_text: PropTypes.string,
  rootClassName: PropTypes.string,
  text: PropTypes.string,
  image_src: PropTypes.string,
}

export default Footer
