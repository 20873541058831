import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.module.css'
import Home from './views/home'
import Games from './views/games'
import PrivacyPolicy from './views/privacy-policy'
import WarpingHorizon from './views/warping-horizon'
import EscapeRoom from './views/escape-room'
import Contactus from './views/contactus'
import Aboutus from './views/aboutus'

const App = () => {
  return (
    <Router>
      <div>
        <Route exact component={Home} path="/" />
        <Route exact component={Games} path="/games" />
        <Route exact component={PrivacyPolicy} path="/privacy-policy" />
        <Route exact component={WarpingHorizon} path="/warping-horizon" />
        <Route exact component={EscapeRoom} path="/escape-room" />
        <Route exact component={Contactus} path="/contactus" />
        <Route exact component={Aboutus} path="/aboutus" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
