import React from 'react'

import PropTypes from 'prop-types'

import styles from './text.module.css'

const Text = (props) => {
  return (
    <div className={` ${styles['container']} ${styles[props.rootClassName]} `}>
      <div className={styles['container1']}>
        <h1 className={styles['Heading']}>
          <span>Games</span>
        </h1>
      </div>
    </div>
  )
}

Text.defaultProps = {
  rootClassName: '',
}

Text.propTypes = {
  rootClassName: PropTypes.string,
}

export default Text
