import React from 'react'
import ReactDOM from 'react-dom'

class Recaptcha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            captchaActive: false
        };
        this.handleLoad = this.handleLoad.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.handleLoad();
        if (location.hash == '#privacypolicy') {
            this.showPrivacyPolicy();
        }
        const node = ReactDOM.findDOMNode(this);
        if (node instanceof HTMLElement) {
            node.querySelector('form').addEventListener('submit', this.asyncSubmit);
        }
    }

    asyncSubmit(event) {
        event.preventDefault()
        const data = new FormData(event.target);
        fetch(
            this.getAttribute('action'),
            {
                method: 'POST',
                body: data,
            }
        )
            .then(res => res.json())
            .then(
            (result) => {
                if (result.status == 'OK') {
                    event.target.setAttribute('style', 'display: none;');
                    let p = document.createElement('p');
                    p.innerText = 'Message sent for team!';
                    event.target.parentNode.appendChild(p);
                }
                    
                },
                (error) => {
                }
            )
    }

    componentWillUnmount() {
        this.captchaActive = false;
    }

    handleLoad() {
        const node = ReactDOM.findDOMNode(this);
        if (node instanceof HTMLElement) {
            node.querySelector('input').addEventListener('keydown', this.handleChange);
        }
    }

    handleChange(event) {
        if (!this.captchaActive) {
            this.captchaActive = true;
            let script = document.createElement('script');
            script.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
            script.setAttribute('async', '');
            script.setAttribute('defer', '');
            document.getElementsByTagName('body')[0].appendChild(script);
        }
    }

    showPrivacyPolicy() {
        let script = document.createElement('iframe');
        script.setAttribute('src', '/privacy-policy');
        script.setAttribute('style', 'z-index: 100;position:fixed;top:0;left:0;width:100%;height:100%;');
        document.getElementsByTagName('body')[0].appendChild(script);
    }
}

export default Recaptcha
