import React from 'react'
import { useState } from 'react';
import PropTypes from 'prop-types'

import NavigationLinks4 from './navigation-links4'
import projectStyles from '../style.module.css'
import styles from './header.module.css'

const Header = (props) => {

    const [hamburgerOpen, setHamburgerOpen] = useState(false);

  return (
    <header className={` ${styles['Header']} ${styles[props.rootClassName]} `}>
      <img
        alt={props.image_alt}
        src={props.image_src}
        className={styles['image']}
      />
      <div className={styles['Nav']}>
        <NavigationLinks4 rootClassName="rootClassName"></NavigationLinks4>
      </div>
      <div
        className={` ${styles['MenuBurger']} ${projectStyles['white']} ${projectStyles['teleport-menu-burger']} `}
              onClick={() => setHamburgerOpen(true)}
     >
        <svg
          viewBox="0 0 1024 1024"
          className={` ${styles['Icon']} ${projectStyles['white']} `}
        >
          <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
        </svg>
      </div>
      <div
              className={` ${projectStyles['teleport-menu-mobile']} ${styles['MenuMobile']} `}
              style={{ display: hamburgerOpen ? "block" : "none" }}
      >
        <div className={styles['Nav1']}>
          <div className={styles['Container']}>
            <div
              className={` ${styles['MenuClose']} ${projectStyles['teleport-menu-close']} `}
                          onClick={() => setHamburgerOpen(false)}
            >
              <svg viewBox="0 0 1024 1024" className={styles['icon2']}>
                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
              </svg>
            </div>
          </div>
          <NavigationLinks4 rootClassName="rootClassName1"></NavigationLinks4>
        </div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  image_alt: 'logo',
  image_src: '/playground_assets/logo_white.svg',
  rootClassName: '',
}

Header.propTypes = {
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Header
