import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import projectStyles from '../style.module.css'
import styles from './home.module.css'
import Recaptcha from '../components/recaptcha'

class Home extends Recaptcha {
    render() {
        return (
            <div className={styles['container']}>
                <Helmet>
                    <title>BRT</title>
                    <meta property="og:title" content="BRT" />
                </Helmet>
                <Header></Header>
                <div className={styles['Banner']}>
                    <h1 className={styles['Heading']}>Welcome to BRT Interactive</h1>
                    <span className={styles['text']}>
                        <span>
                            <span>Learn more about what we do</span>
                        </span>
                    </span>
                    <Link
                        to="/aboutus"
                        className={` ${styles['navlink']} ${projectStyles['button']} `}
                    >
                        Read More
        </Link>
                    <div className={styles['container1']}></div>
                </div>
                <div className={styles['Banner1']}>
                    <span className={styles['text3']}>
                        Sign up to our newsletter and stay up to date
        </span>
                    <div className={styles['container2']}>
                        <form
                            action="https://data.brtinteractive.com/brt_site/subscribe"
                            method="POST"
                            className={styles['form']}
                        >
                            <div className={styles['container3']}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email here..."
                                    className={` ${projectStyles['captcha-indicator']} ${projectStyles['subscribe-input']} ${projectStyles['input']} `}
                                />
                                <button
                                    type="submit"
                                    className={` ${styles['button']} ${projectStyles['button']} `}
                                >
                                    Subscribe
              </button>
                            </div>
                            <div className={styles['container4']}>
                                <div
                                    data-sitekey="6LerefgdAAAAAPpWLesDesOoCY2boO5xFTr8w58m"
                                    className="g-recaptcha"
                                ></div>
                            </div>
                        </form>
                    </div>
                    <span className={styles['text4']}>
                        <span className={styles['text5']}>
                            By submitting your information, you are granting us permission to
                            email
          </span>
                        <br></br>
                        <span className={styles['text7']}>
                            {' '}
                            you. You may unsubscribe at any time.
          </span>
                    </span>
                </div>
                <Footer rootClassName="rootClassName5"></Footer>
            </div>
        )
    }
}

export default Home
