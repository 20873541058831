import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Text from '../components/text'
import GalleryCard1 from '../components/gallery-card1'
import Footer from '../components/footer'
import styles from './games.module.css'

const Games = () => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>Games - BRT</title>
        <meta property="og:title" content="Games - BRT" />
      </Helmet>
      <Header rootClassName="rootClassName3"></Header>
      <Text rootClassName="rootClassName"></Text>
      <div className={styles['Gallery']}>
        <Link to="/escape-room">
          <GalleryCard1
            rootClassName="rootClassName"
            className={styles['GalleryCard1']}
          ></GalleryCard1>
        </Link>
        <Link to="/warping-horizon">
          <GalleryCard1
            title="Warping Horizon"
            subtitle="Adventure game."
            image_src="https://images.unsplash.com/photo-1579551053957-ee77f9b970c7?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDQ2fHx3b29kc3xlbnwwfHx8fDE2MjY0NDc1ODg&amp;ixlib=rb-1.2.1&amp;w=1000"
            rootClassName="rootClassName1"
            className={styles['GalleryCard2']}
          ></GalleryCard1>
        </Link>
      </div>
      <Footer rootClassName="rootClassName3"></Footer>
    </div>
  )
}

export default Games
