import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import styles from './warping-horizon.module.css'

const WarpingHorizon = () => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>Warping Horizon - BRT</title>
        <meta property="og:title" content="Warping Horizon - BRT" />
      </Helmet>
      <Header rootClassName="rootClassName4"></Header>
      <div className={styles['container1']}>
        <h1 className={styles['text']}>
          <span>Warping Horizon</span>
        </h1>
      </div>
      <Footer rootClassName="rootClassName4"></Footer>
    </div>
  )
}

export default WarpingHorizon
