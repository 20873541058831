import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import GalleryCard11 from '../components/gallery-card11'
import Footer from '../components/footer'
import styles from './aboutus.module.css'

const Aboutus = () => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>About us - BRT</title>
        <meta property="og:title" content="About us - BRT" />
      </Helmet>
      <Header rootClassName="rootClassName"></Header>
      <div className={styles['Banner']}>
        <h1 className={styles['text']}>
          <span>Welcome to our website</span>
        </h1>
        <span className={styles['text2']}>
          <span>
            We are a group of friends who would create innovative and creative
            games for gamer communities. We most prefer Unreal Engine
            environment although we tried out other game engines and use them
            day after day.
          </span>
        </span>
      </div>
      <span className={styles['text4']}>Team members</span>
      <div className={styles['Gallery']}>
        <GalleryCard11
          title="Gábor Balázs"
          subtitle="Artist and Designer"
          image_src="https://images.unsplash.com/photo-1439853949127-fa647821eba0?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE4fHxuYXR1cmV8ZW58MHx8fHwxNjI2NDQ3ODAw&amp;ixlib=rb-1.2.1&amp;w=1000"
          rootClassName="rootClassName3"
        ></GalleryCard11>
        <GalleryCard11
          title="Róbert Rácz"
          subtitle="Software Developer"
          image_src="https://images.unsplash.com/photo-1529948723647-8b7bd77b441c?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDExfHxjbGlmZnxlbnwwfHx8fDE2MjY0NDc4MjQ&amp;ixlib=rb-1.2.1&amp;w=1000"
          rootClassName="rootClassName4"
        ></GalleryCard11>
        <GalleryCard11
          title="Gergő Tóth"
          subtitle="Software Developer"
          image_src="https://images.unsplash.com/photo-1553570739-330b8db8a925?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDI0fHxvY2VhbnxlbnwwfHx8fDE2MjY0NDc4ODQ&amp;ixlib=rb-1.2.1&amp;w=1000"
          rootClassName="rootClassName5"
        ></GalleryCard11>
      </div>
      <Footer rootClassName="rootClassName"></Footer>
    </div>
  )
}

export default Aboutus
