import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import styles from './navigation-links4.module.css'

const NavigationLinks4 = (props) => {
  return (
    <nav className={` ${styles['Nav']} ${styles[props.rootClassName]} `}>
      <Link to="/" className={styles['navlink']}>
        <span>Home</span>
      </Link>
      <Link to="/games" className={styles['navlink1']}>
        {props.text1}
      </Link>
      <Link to="/contactus" className={styles['navlink2']}>
        {props.text3}
      </Link>
    </nav>
  )
}

NavigationLinks4.defaultProps = {
  text1: 'Games',
  rootClassName: '',
  text: 'Home',
  text3: 'Contact us',
}

NavigationLinks4.propTypes = {
  text1: PropTypes.string,
  rootClassName: PropTypes.string,
  text: PropTypes.string,
  text3: PropTypes.string,
}

export default NavigationLinks4
